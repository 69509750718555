export function useServerDowntime() {
  const announcement = useState<string>(() => '')
  const isInDowntime = useState<boolean>(() => false)
  const waitDowntimeSeconds = useState<number>(() => 0)

  const { pause, resume, isActive } = useIntervalFn(updateDowntimeStatus, 3000, { immediate: false })

  async function updateDowntimeStatus(needLoopCheck: boolean = false) {
    const response = await $fetch<{ sill: number, announcement: string }>(import.meta.env.VITE_CHECK_SERVER_DOWNTIME_ENDPOINT, { responseType: 'json' })
    announcement.value = response.announcement
    isInDowntime.value = response.sill > 0
    waitDowntimeSeconds.value = response.sill
    if (isInDowntime.value && response.sill < 5)
      needLoopCheck && !isActive.value && resume()
    else
      pause()
    return { isInDowntimeNow: isInDowntime.value, needDisconnectAll: response.sill > 5 }
  }

  return {
    isInDowntime,
    announcement,
    waitDowntimeSeconds,
    updateDowntimeStatus,
  }
}

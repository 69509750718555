<script setup lang="ts">
defineProps<{
  needTopZero?: boolean
  isFullscreen?: boolean
}>()

const { announcement, updateDowntimeStatus } = useServerDowntime()

onMounted(() => {
  updateDowntimeStatus()
})
</script>

<template>
  <LazyClientOnly v-if="announcement">
    <div class="absolute left-0 top-17 z-40 w-full" :class="{ 'top-0 pointer-events-none': needTopZero, 'fixed top-0': isFullscreen }">
      <div class="relative bg-gray-3 bg-op-90">
        <LayoutMarquee :text="announcement" />
      </div>
    </div>
  </LazyClientOnly>
</template>

<script setup lang="ts">
const props = defineProps<{
  text: string
}>()

const autoFillText = ref(1)

watch(() => props.text, () => {
  autoFillText.value = Math.ceil(window.innerWidth / (props.text.length * 8))
}, { immediate: true })

const duration = computed(() => {
  return props.text.repeat(autoFillText.value).length * 0.2 + 5
})
</script>

<template>
  <Vue3Marquee
    :duration="duration"
    :pause-on-hover="true"
  >
    <p class="marquee-content ws-nowrap text-3.5 c-#fff font-600 lh-11">
      <span v-for="count in autoFillText" :key="count">{{ text }}</span>
    </p>
  </Vue3Marquee>
</template>

<style lang="scss" scoped>
.marquee-content {
  span {
    &::after {
      content: '/';
      margin: 0 40px;
      color: rgba(255, 255, 255, 0.3);
    }
  }
}
</style>
